import React from "react";
import { Link, graphql } from "gatsby";
import styled from "@emotion/styled";

import Layout from "../components/layout";

const Section = styled.section`
  & + & {
    margin-top: 32px;
  }
`;

const Footer = styled.footer`
  display: flex;
  justify-content: space-around;
  margin: 32px 0;
`;

const FauxLink = styled.span`
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-bottom-color 0.2s ease-in-out;
  border-bottom: dotted 1px rgba(255, 255, 255, 0.5);
  text-decoration: none;
  color: inherit;
  cursor: default;
  opacity: 0.5;
`;

class BlogListTemplate extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const { posts } = data;
    const { currentPage, numPages } = pageContext;
    const prevPage =
      currentPage - 1 === 1 ? "/blog" : `/blog/${(currentPage - 1).toString()}`;
    const nextPage = `/blog/${(currentPage + 1).toString()}`;
    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === numPages;

    return (
      <Layout location={this.props.location} title="Posts">
        <header>
          <h1 className="major">Posts</h1>
        </header>
        {posts.edges
          .map(edge => edge.node)
          .map(post => (
            <Section key={post.fields.slug}>
              <h2 className="minor">{post.frontmatter.title}</h2>
              <p>{post.excerpt}</p>
              <Link to={post.fields.slug} className="button small">
                Read More
              </Link>
            </Section>
          ))}
        <Footer>
          {isFirstPage ? (
            <FauxLink>Previous</FauxLink>
          ) : (
            <Link to={prevPage} rel="prev">
              Previous
            </Link>
          )}

          {isLastPage ? (
            <FauxLink>Next</FauxLink>
          ) : (
            <Link to={nextPage} rel="next">
              Next
            </Link>
          )}
        </Footer>
      </Layout>
    );
  }
}

export default BlogListTemplate;

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    posts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
          excerpt
        }
      }
    }
  }
`;
